import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Approve from "../Assign Project To Member/AssignProjectToMember";
import CreateProject from "../CreatPro/CreateProject";
import QueryProjects from "../QueryProjects/QueryProjects";
import TimeTrackingData from "../TimeTrackingData/TimeTrackingData";
import CreateUsers from "../CreateUsers/CreateUsers";
import Profile from "../Profile/Profile";
import Reset from "../Reset/Reset";
import Approvetime from "../Approve/Approve";
import Users from "../TimeTracking/time-tracking";
import styles from "./Admin.module.css";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Admin: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<string>("System services");
  const [showApproveContent, setShowApproveContent] = useState<boolean>(false);
  const [showCreatePro, setshowCreatePro] = useState<boolean>(false);
  const [showCreateUsers, setshowCreateUsers] = useState<boolean>(false);
  const [showReset, setshowReset] = useState<boolean>(false);
  const [showQueryPro, setshowQueryPro] = useState<boolean>(false);
  const [showTimeTracking, setshowTimeTracking] = useState<boolean>(false);
  const [showTimeTrackingData, setshowTimeTrackingData] = useState<boolean>(
    false
  );
  const [showProfile, setshowProfile] = useState<boolean>(false);
  const [showApprove, setshowApprove] = useState<boolean>(false);
  const [showman, setshowman] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [submenu, setSubmenu] = useState<null | string>(null);
  const open = Boolean(anchorEl);
  const submenuOpen = Boolean(submenuAnchorEl);

  const handleClickM = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubmenuClick = (
    event: React.MouseEvent<HTMLLIElement>,
    submenu: string
  ) => {
    setSubmenuAnchorEl(event.currentTarget);
    setSubmenu(submenu);
  };

  const handleCloseM = (selected: string) => {
    setSelectedItem(selected);
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
    setShowApproveContent(selected === "AssignProject");
    setshowCreatePro(selected === "CreateProject");
    setshowCreateUsers(selected === "CreateUser");
    setshowReset(selected === "Reset");
    setshowTimeTracking(selected === "Tracker");
    setshowTimeTrackingData(selected === "ProjectReport");
    setshowQueryPro(selected === "UserReport");
    setshowProfile(selected === "Profile");
    setshowman(selected === "Trackermanual");
    setshowApprove(selected === "Approve");
  };

  return (
    <>
      <section className={styles.bg}>
        <Card className={styles.card}>
          <CardContent>
            <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                className={styles.meu}
                disableElevation
                onClick={handleClickM}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {selectedItem}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleCloseM(selectedItem)}
              >
                <MenuItem
                  onClick={(event) =>
                    handleSubmenuClick(event, "Time Tracking")
                  }
                  disableRipple
                  aria-haspopup="true"
                  aria-controls={
                    submenuOpen ? "time-tracking-submenu" : undefined
                  }
                  aria-expanded={submenuOpen ? "true" : undefined}
                >
                  Time Tracking
                  <KeyboardArrowRightIcon />
                </MenuItem>
                <MenuItem
                  onClick={(event) => handleSubmenuClick(event, "Projects")}
                  disableRipple
                  aria-haspopup="true"
                  aria-controls={
                    submenuOpen ? "projects-submenu" : undefined
                  }
                  aria-expanded={submenuOpen ? "true" : undefined}
                >
                  Projects
                  <KeyboardArrowRightIcon />
                </MenuItem>
                <MenuItem
                  onClick={(event) => handleSubmenuClick(event, "Users")}
                  disableRipple
                  aria-haspopup="true"
                  aria-controls={
                    submenuOpen ? "users-submenu" : undefined
                  }
                  aria-expanded={submenuOpen ? "true" : undefined}
                >
                  Users
                  <KeyboardArrowRightIcon />
                </MenuItem>
                <MenuItem
                  onClick={(event) => handleSubmenuClick(event, "Setting")}
                  disableRipple
                  aria-haspopup="true"
                  aria-controls={
                    submenuOpen ? "setting-submenu" : undefined
                  }
                  aria-expanded={submenuOpen ? "true" : undefined}
                >
                  Setting
                  <KeyboardArrowRightIcon />
                </MenuItem>
              </StyledMenu>
              <StyledMenu
                style={{ left: "180px" }}
                id="time-tracking-submenu"
                anchorEl={submenuAnchorEl}
                open={submenuOpen && submenu === "Time Tracking"}
                onClose={() => setSubmenuAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                MenuListProps={{
                  "aria-labelledby": "time-tracking-menu",
                }}
              >
                <MenuItem onClick={() => handleCloseM("Tracker")} disableRipple>
                  Tracker
                </MenuItem>
                <MenuItem onClick={() => handleCloseM("Approve")} disableRipple>
                  Approve
                </MenuItem>
              </StyledMenu>
              <StyledMenu
                id="projects-submenu"
                style={{ left: "180px" }}
                anchorEl={submenuAnchorEl}
                open={submenuOpen && submenu === "Projects"}
                onClose={() => setSubmenuAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                MenuListProps={{
                  "aria-labelledby": "projects-menu",
                }}
              >
                <MenuItem
                  onClick={() => handleCloseM("AssignProject")}
                  disableRipple
                >
                  Assign Project
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseM("CreateProject")}
                  disableRipple
                >
                  Create Project
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseM("ProjectReport")}
                  disableRipple
                >
                  Project Report
                </MenuItem>
              </StyledMenu>
              <StyledMenu
                style={{ left: "180px" }}
                id="users-submenu"
                anchorEl={submenuAnchorEl}
                open={submenuOpen && submenu === "Users"}
                onClose={() => setSubmenuAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                MenuListProps={{
                  "aria-labelledby": "users-menu",
                }}
              >
                <MenuItem
                  onClick={() => handleCloseM("CreateUser")}
                  disableRipple
                >
                  Create Users
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseM("UserReport")}
                  disableRipple
                >
                  User Report
                </MenuItem>
              </StyledMenu>
              <StyledMenu
                style={{ left: "180px" }}
                id="setting-submenu"
                anchorEl={submenuAnchorEl}
                open={submenuOpen && submenu === "Setting"}
                onClose={() => setSubmenuAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                MenuListProps={{
                  "aria-labelledby": "setting-menu",
                }}
              >
                <MenuItem onClick={() => handleCloseM("Profile")} disableRipple>
                  Profile
                </MenuItem>
                <MenuItem onClick={() => handleCloseM("Reset")} disableRipple>
                  Reset
                </MenuItem>
              </StyledMenu>
            </div>
            {showApproveContent && (
              <div>
                <Approve />
              </div>
            )}
            {showCreatePro && (
              <div>
                <CreateProject />
              </div>
            )}
            {showReset && (
              <div>
                <Reset />
              </div>
            )}
            {showCreateUsers && (
              <div>
                <CreateUsers />
              </div>
            )}
            {showQueryPro && (
              <div>
                <QueryProjects />
              </div>
            )}
            {showTimeTracking && (
              <div>
                <Users />
              </div>
            )}
            {showTimeTrackingData && (
              <div>
                <TimeTrackingData />
              </div>
            )}
            {showProfile && (
              <div>
                <Profile />
              </div>
            )}
            {showApprove && (
              <div>
                <Approvetime />
              </div>
            )}
          </CardContent>
        </Card>
      </section>
    </>
  );
};

export default Admin;
