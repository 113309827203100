import React, { useState, useEffect } from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import User from "./component/TimeTracking/time-tracking";
import Layout from "./component/Layout/Layout";
import Admin from "./component/Admin/Admin";
import Login from "./component/Login/Login";
import Register from "./component/Register/Register";
import Report from "./component/Report/Report";
import Verify from "./component/verify/verify";
import Initializer from "./global/Initializer";
import MySnackbar from "./component/snakbar/snakbar";
import { Provider } from "react-redux";
import DotLottie from "../src/intro/dot-lottie/dot-lottie";
import styles from "./App.module.css";
import IntroAnimation from "../src/intro/Animation - 1717500961843 (1).json";
import store from "./store/index";
import MainUser from "./component/TimeTracking/MainUser";
function App() {
  const [showIntroGif, setShowIntroGif] = useState(true);
  const [terminateIntro, setTerminateIntro] = useState(false);

  useEffect(() => {
    if (!showIntroGif) {
      const introDiv = document.querySelector(".intro");
      introDiv?.classList.add(styles.introDivFade);
      const timer = setTimeout(() => {
        setTerminateIntro(true);
      }, 750);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showIntroGif]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntroGif(false);
    }, 4250);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  function Router() {
    return useRoutes([
      {
        path: "",
        element: <Layout />,
        children: [
          { path: "/User", element: <MainUser /> },
          { path: "/Admin", element: <Admin /> },
          { path: "/Login", element: <Login /> },
          { path: "/Report", element: <Report /> },
          { path: "/Register", element: <Register /> },
          { path: "/verify", element: <Verify /> },
        ],
      },
    ]);
  }

  return (
    <div className={styles.root}>
      {!terminateIntro ? (
        <div className={`${styles.introDiv} intro`}>
          <DotLottie animation={IntroAnimation} />
          <div className={styles.logo}></div>
          <div className={styles.tit}>TickTrackIT</div>
        </div>
      ) : (
        <Provider store={store}>
          <BrowserRouter>
            <Initializer>
              <Router />
              <MySnackbar />
            </Initializer>
          </BrowserRouter>
        </Provider>
      )}
    </div>
  );
}

export default App;
