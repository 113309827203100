import React, { useState, useEffect } from "react";
import styles from "./Report.module.css";
import axiosInstance from "../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import { _DateToISOFormat } from "../../utils/date";
import { _EncodeParams } from "../../utils/request";
import Button from "@mui/material/Button";

interface TimeTrackingData {
  pk: number;
  task: string;
  startTime: string | null;
  endTime: string | null;
  duration: string | null;
  payment: boolean;
}

const Report: React.FC = () => {
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL || "";

  const [timeTrackingData, setTimeTrackingData] = useState<TimeTrackingData[]>(
    []
  );
  const [filteredData, setFilteredData] = useState<TimeTrackingData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isDataVisible, setIsDataVisible] = useState(false); // State for visibility

  const today = new Date();
const thirdDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  .toLocaleDateString("en-CA"); // This will format the date as 'YYYY-MM-DD' in local time zone
const [customDateFrom, setCustomDateFrom] = useState<string>(thirdDayOfMonth);
const [customDateTo, setCustomDateTo] = useState<string>(
  today.toLocaleDateString("en-CA")
);

  // const today = new Date();
  // const thirdDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  //   .toISOString()
  //   .split("T")[0];
  // const [customDateFrom, setCustomDateFrom] = useState<string>(thirdDayOfMonth);
  // const [customDateTo, setCustomDateTo] = useState<string>(
  //   today.toISOString().split("T")[0]
  // );

  const location = useLocation();
  const navigate = useNavigate();
  const { projectid, memberId } = (location.state as any) || {};

  const [paidPks, setPaidPks] = useState<number[]>([]);

  useEffect(() => {
    if (isDataVisible && customDateFrom && customDateTo) {
      fetchDataFromEndpoint();
    }
  }, [isDataVisible, currentPage, customDateFrom, customDateTo]);

  const fetchDataFromEndpoint = async () => {
    console.log("Fetching time tracking data...");
    setIsLoading(true);
    try {
      const params = {
        member_id: memberId,
        project_id: projectid,
        from_date: _DateToISOFormat(customDateFrom),
        to_date: _DateToISOFormat(customDateTo, true),
      };
      const queryString = _EncodeParams(params);
      let url = `${apiURL}/api/time-tracking/?${queryString}`;
      const response = await axiosInstance.get<{
        isOk: boolean;
        message: string;
        data: TimeTrackingData[];
      }>(url);
      const { isOk, message, data } = response.data;
      console.log("Received response:", response.data);
      if (isOk) {
        setTimeTrackingData(data);
        setFilteredData(data); // Set filtered data to the fetched data
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching time tracking data:", error);
      dispatch(
        uiActions.updateNotification({
          message: "Error fetching time tracking data.",
          level: "error",
        })
      );
    } finally {
      setIsLoading(false);
      console.log("Fetching data completed.");
    }
  };

  const handleDisplayButtonClick = () => {
    setIsDataVisible(true);
  };

  const handleDetailsButtonClick2 = () => {
    console.log("Home button clicked.");
    navigate("../Admin");
  };

  const handleCustomDateFromChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomDateFrom(event.target.value);
  };

  const handleCustomDateToChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomDateTo(event.target.value);
  };

  useEffect(() => {
    const newDateFrom = new Date(customDateFrom);
    const newMaxDate = new Date(newDateFrom);
    newMaxDate.setDate(newDateFrom.getDate() + 30);
    const maxDate = newMaxDate.toISOString().split("T")[0];
    if (customDateTo > maxDate) {
      setCustomDateTo(maxDate);
    }
    document.getElementById("customDateTo")?.setAttribute("max", maxDate);
  }, [customDateFrom]);

  const calculateTotalDuration = (data: TimeTrackingData[]): string => {
    let totalSeconds = 0;

    data.forEach((item) => {
      if (item.duration) {
        const [hours, minutes, seconds] = item.duration.split(":").map(Number);
        totalSeconds +=
          (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
      }
    });

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const calculateDurationByPaymentStatus = (
    data: TimeTrackingData[],
    isPaid: boolean
  ): string => {
    let totalSeconds = 0;

    data.forEach((item) => {
      if (item.duration && item.payment === isPaid) {
        const [hours, minutes, seconds] = item.duration.split(":").map(Number);
        totalSeconds +=
          (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
      }
    });

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const handlePayButtonClick1 = async () => {
    console.log("Pay button clicked.");

    const selectedPks = filteredData
      .filter((task) => !task.payment)
      .map((task) => task.pk);

    if (selectedPks.length === 0) {
      dispatch(
        uiActions.updateNotification({
          message: "No tasks selected for payment.",
          level: "warning",
        })
      );
      return;
    }

    try {
      const url = `${apiURL}/api/update-payment-status/`;
      const response = await axiosInstance.post(url, { pks: selectedPks });
      const { isOk, message } = response.data;

      if (isOk) {
        setPaidPks((prev) => [...prev, ...selectedPks]);

        dispatch(
          uiActions.updateNotification({
            message: "Payment status updated successfully.",
            level: "success",
          })
        );
        fetchDataFromEndpoint();
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error updating payment status:", error);
      dispatch(
        uiActions.updateNotification({
          message: "Error updating payment status.",
          level: "error",
        })
      );
    }
  };
  const handlePayButtonClick = async (pk: number) => {
    console.log("Pay button clicked for pk:", pk);
  
    try {
      const url = `${apiURL}/api/update-payment-status/`;
      const response = await axiosInstance.post(url, { pks: [pk] });
      const { isOk, message } = response.data;
  
      if (isOk) {
        setPaidPks((prev) => [...prev, pk]);
  
        dispatch(
          uiActions.updateNotification({
            message: "Payment status updated successfully.",
            level: "success",
          })
        );
        fetchDataFromEndpoint(); 
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error updating payment status:", error);
      dispatch(
        uiActions.updateNotification({
          message: "Error updating payment status.",
          level: "error",
        })
      );
    }
  };
  
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <section className={styles.bg}>
      <button className={styles.btn} onClick={handleDisplayButtonClick}>
        Display
      </button>
      <button className={styles.btn} onClick={handleDetailsButtonClick2}>
        Home
      </button>
      <button className={styles.btn} onClick={handlePayButtonClick1}>
        Pay All
      </button>
      <div className={styles.customDate}>
        <label htmlFor="customDateFrom" className={styles.customDateLabel}>
          From:
        </label>
        <input
          id="customDateFrom"
          type="date"
          className={styles.customDateInput}
          value={customDateFrom}
          onChange={handleCustomDateFromChange}
        />
        <label htmlFor="customDateTo" className={styles.customDateLabel}>
          To:
        </label>
        <input
          id="customDateTo"
          type="date"
          className={styles.customDateInput}
          value={customDateTo}
          onChange={handleCustomDateToChange}
        />
      </div>
      {isDataVisible && (
        <table className={styles.tb}>
          <thead>
            <tr>
              <th>Task</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Payment Status</th> 
              <th>Duration</th>


            </tr>
          </thead>
          <tbody>
            {currentItems.map((item: any, index) => (
              <tr
                key={index}
                className={item.payment ? styles.paidRow : styles.unpaidRow}
              >
                <td>{item.task}</td>
                <td>{new Date(item.startTime).toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}</td>
                <td>{new Date(item.endTime).toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}</td>
                <td>
          <Button style={{alignItems:"center" ,marginLeft:"60px"}}
            variant="contained"
            className={styles.btn} 
                        onClick={() => handlePayButtonClick(item.pk)}
          >
            Pay
          </Button>
        </td>
                <td>{item.duration || "N/A"}</td>
               
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                style={{ backgroundColor: "#2963545C" }}
                className={styles.totalLabel}
                colSpan={4}
              >
                Total Duration
              </td>
              <td style={{ backgroundColor: "#2963545C" }}>
                {calculateTotalDuration(filteredData)}
              </td>
              <td></td>
            </tr>
            <tr>
              <td
                style={{ backgroundColor: "#2963545C" }}
                className={styles.totalLabel}
                colSpan={4}
              >
                Total Paid Duration
              </td>
              <td style={{ backgroundColor: "#2963545C" }}>
                {calculateDurationByPaymentStatus(filteredData, true)}
              </td>
              <td></td>
            </tr>
            <tr>
              <td
                style={{ backgroundColor: "#2963545C" }}
                className={styles.totalLabel}
                colSpan={4}
              >
                Total Unpaid Duration
              </td>
              <td style={{ backgroundColor: "#2963545C" }}>
                {calculateDurationByPaymentStatus(filteredData, false)}
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      )}
      {isDataVisible && (
        <div className={styles.pagination}>
          {Array.from(
            { length: Math.ceil(filteredData.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      )}
    </section>
  );
};

export default Report;
