import React, { useState, useEffect, MouseEvent } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import styles from "./assets/scss/time-tracking-manual.module.css";
import axiosInstance from "../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import DateTime from "../dateTime/DateTime";
import { _DateTimeToLocalISOString, _DateToISOFormat } from "../../utils/date";

interface Project {
  id: number;
  name: string;
}

interface QueriedProject {
  project_name: string;
  total_duration: string;
}

interface RootState {
  app: {
    allData: {
      userId: number;
      userName: string;
    };
  };
}

const User: React.FC = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date>(
    new Date(new Date().setHours(new Date().getHours() + 1))
  );
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [queriedProjects, setQueriedProjects] = useState<QueriedProject[]>([]);
  const apiURL = process.env.REACT_APP_API_URL || "";
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [Task, setTask] = useState<string>("");

  const { userId, userName } = useSelector(
    (state: RootState) => state.app.allData
  );

  const handleQuerySelectorClick = async () => {
    try {
      const response = await axios.get(
        apiURL + `/api/member/${userId}/projects/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.projects) {
        setQueriedProjects(response.data.projects);
      }
    } catch (error: any) {
      console.error("Error fetching projects:", error.message);
    }
    setShowTable(true);
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (
    projectId: number | null,
    projectName: string | null
  ) => {
    setTimeout(() => {
      setAnchorEl(null);
      setOpen(false);
      if (projectId && projectName) {
        setSelectedProject({ id: projectId, name: projectName });
      }
    }, 100);
  };

  const handleSubmit = async () => {
    const startUTCTimeend = _DateTimeToLocalISOString(startTime);
    const endUTCTimeend = _DateTimeToLocalISOString(endTime);

    const duration =
      new Date(endTime).getTime() - new Date(startTime).getTime();

    if (duration > 0 && duration <= 5 * 3600000) {
      const formattedDuration = formatElapsedTime(duration);

      const data = {
        userId: userId,
        projectId: selectedProject?.id,
        startTime: startUTCTimeend,
        duration: formattedDuration,
        endTime: endUTCTimeend,
        task: Task,
        auto: "manual",
      };

      try {
        const response = await axiosInstance.post(
          apiURL + "/api/add_manual_time/",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { isOk, message } = response.data;
        if (isOk) {
          setStartTime(new Date());
          setEndTime(new Date(new Date().setHours(new Date().getHours() + 1)));
          setTask("");
          dispatch(
            uiActions.updateNotification({
              message: "Time added successfully",
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "error",
            })
          );
        }

        handleQuerySelectorClick();
      } catch (error: any) {
        console.error("Error sending time tracking data:", error.message);
      }
    } else {
      dispatch(
        uiActions.updateNotification({
          message: "Max hours to enter manually is 5 hrs",
          level: "error",
        })
      );
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (startTime) {
      interval = setInterval(() => {
        const now = new Date();
        const elapsed = now.getTime() - startTime.getTime();
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [startTime]);

  useEffect(() => {
    const fetchMemberProjects = async () => {
      try {
        const response = await axios.get(
          apiURL + `/api/member-projects/${userId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.data) {
          const projects = response.data.data;
          setProjectList(projects);
        }
      } catch (error: any) {
        console.error("Error fetching member projects:", error.message);
      }
    };

    fetchMemberProjects();
  }, [userId, token]);

  const formatElapsedTime = (time: number) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const _StartTimeChangeHandler = (evt: any) => {
    const newStartTime = new Date(evt.$d);
    setStartTime(newStartTime);

    // If the new start time is after the current end time, adjust the end time
    if (newStartTime > endTime) {
      setEndTime(new Date(newStartTime.getTime() + 3600000)); // Default to 1 hour later
    }
  };

  const _EndTimeChangeHandler = (evt: any) => {
    setEndTime(new Date(evt.$d));
  };

  return (
    <>
      <div className={styles.contentContainer}>
        <Button
          className={styles.menu}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {selectedProject ? selectedProject.name : "Select Project"}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(null, null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {projectList.map((project) => (
            <MenuItem
              className={styles.me}
              key={project.id}
              onClick={() => handleClose(project.id, project.name)}
            >
              {project.name}
            </MenuItem>
          ))}
        </Menu>
        <input
          className={styles.input2}
          type="text"
          value={Task}
          onChange={(e) => setTask(e.target.value)}
          placeholder="Task"
        />
        <DateTime
          label="Start time"
          timeChangeHandler={_StartTimeChangeHandler}
          dateTime={startTime}
        />
        <DateTime
          label="End time"
          timeChangeHandler={_EndTimeChangeHandler}
          dateTime={endTime}
          minDateTime={startTime}
        />
        <Button
          className={styles.btn}
          variant="contained"
          color="success"
          onClick={handleSubmit}
          disabled={
            !startTime || !endTime || !selectedProject || !Task || !userId
          }
        >
          Submit
        </Button>
      </div>

      <div style={{ marginTop: "25px" }}>
        {showTable && (
          <table>
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Total Duration</th>
              </tr>
            </thead>
            <tbody>
              {queriedProjects.map((project, index) => (
                <tr key={index}>
                  <td>{project.project_name}</td>
                  <td>{project.total_duration}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default User;
