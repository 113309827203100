import { Button, Menu, MenuItem, TextField } from "@mui/material";
import axiosInstance from "../Utils/Utils";
import React, { useState, useEffect } from "react";
import styles from "./Admin.module.css";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import { _DateToISOFormat } from "../../utils/date";

// Define types for the state and API responses
interface Project {
  id: number;
  name: string;
}

interface User {
  id: number;
  username: string;
}

interface TimeTrackingResponse {
  isOk: boolean;
  message: string;
  code: number;
  total_time_spent: string;
  users: {
    user_id: number;
    username: string;
    duration: string;
  }[];
}

interface UsernamesResponse {
  isOk: boolean;
  message: string;
  code: number;
  users: User[];
}

interface ProjectsResponse {
  isOk: boolean;
  message: string;
  code: number;
  projects: Project[];
}

const TimeTrackingData: React.FC = () => {
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL!;
  
  const [projectAnchorEl, setProjectAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectIdMap, setProjectIdMap] = useState<{ [key: string]: number }>({});
  const [isTiming, setIsTiming] = useState(false);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [responseData, setResponseData] = useState<TimeTrackingResponse | null>(null);
  const [buttonText, setButtonText] = useState("ADD");
  const [projectName, setProjectName] = useState("");
  const [memberAnchorEl, setMemberAnchorEl] = useState<null | HTMLElement>(null);
  const [usernames, setUsernames] = useState<string[]>([]);
  const [memberIdMap, setMemberIdMap] = useState<{ [key: string]: number }>({});
  const [selectedMembers, setSelectedMembers] = useState<User[]>([]);
  
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  
  const app = useSelector((state: any) => state.app.allData); // Adjust RootState as per your store structure

  const handleClickProject = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProjectAnchorEl(event.currentTarget);
  };

  const handleCloseProject = (project: Project | null) => {
    setProjectAnchorEl(null);
    if (project) {
      setSelectedProject(project);
    }
  };

  const fetchData = async () => {
    if (!selectedProject) return;

    try {
      const projectId = selectedProject.id;
      const response = await axiosInstance.get<TimeTrackingResponse>(
        `${apiURL}/api/time-tracking/${projectId}/`,
        {
          params: {
            from_date: _DateToISOFormat(fromDate),
            to_date: _DateToISOFormat(toDate,true)
          },
        }
      );
      const { isOk, message } = response.data;
      if (isOk) {
        setResponseData(response.data);
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        const response = await axiosInstance.post<UsernamesResponse>(`${apiURL}/api/get_all_usernames/`, {
          entityId: app.entityId,
        });
        const { isOk, message, users } = response.data;
        if (isOk && users) {
          const idMap: { [key: string]: number } = {};
          users.forEach((user) => {
            idMap[user.username] = user.id;
          });
          setUsernames(users.map((user) => user.username));
          setMemberIdMap(idMap);
        } else {
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "error",
            })
          );
        }
      } catch (error) {
        console.error("Error fetching usernames:", error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.post<ProjectsResponse>(`${apiURL}/api/get_all_projects/`, {
          entityId: app.entityId,
        });
        const { isOk, message, projects } = response.data;
        if (isOk && projects) {
          setProjectList(projects);
          const idMap: { [key: string]: number } = {};
          projects.forEach((project) => {
            idMap[project.name] = project.id;
          });
          setProjectIdMap(idMap);
        } else {
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "error",
            })
          );
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchUsernames();
    fetchProjects();
  }, [apiURL, app.entityId, dispatch]);

  const handleAdd = () => {
    if (!selectedProject) {
      console.error("Please select a project.");
      return;
    }

    const projectId = projectIdMap[selectedProject.name];
    const memberIds = selectedMembers.map((member) => member.id);

    const requestData = {
      projectId: projectId,
      memberIds: memberIds,
    };

    axiosInstance
      .post(`${apiURL}/api/add_project_members/`, requestData)
      .then((response) => {
        console.log("Success:", response.data);
        setButtonText("ADDED");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleReset = () => {
    setProjectName("");
    setSelectedMembers([]);
    setSelectedProject(null);
    setButtonText("ADD");
  };

  return (
    <>
      <section className={styles.bg}>
        <div className={styles.menuContainer}>
          <Button
            className={styles.menu}
            id="project-button"
            aria-controls={projectAnchorEl ? "project-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={!!projectAnchorEl}
            onClick={handleClickProject}
            disabled={isTiming}
          >
            {selectedProject ? selectedProject.name : "Select Project"}
          </Button>
        </div>
        <div className={styles.buttonContainer}>
          <Menu
            id="project-menu"
            anchorEl={projectAnchorEl}
            open={Boolean(projectAnchorEl)}
            onClose={() => handleCloseProject(null)}
            MenuListProps={{
              "aria-labelledby": "project-button",
            }}
          >
            {projectList.map((project) => (
              <MenuItem
                className={styles.me}
                key={project.id}
                onClick={() => handleCloseProject(project)}
              >
                {project.name}
              </MenuItem>
            ))}
          </Menu>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchData}
            className={styles.btnzz}
          >
            Time Tracking Data
          </Button>
        </div>
        <div className={styles.dateContainer}>
          <TextField
            label="From Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className={styles.datePicker}
          />
          <TextField
            label="To Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className={styles.datePicker}
          />
        </div>
        {responseData && (
          <div className={styles.card}>
            <p className={styles.totalTime}>Total Time Spent: {responseData.total_time_spent}</p>
            <ul className={styles.dataList}>
              {responseData.users.map((user: any) => ( // Adjust type as per your API response structure
                <li className={styles.dataItem} key={user.user_id}>
                  <span className={styles.dot}></span>
                  <span className={styles.username}>Username: {user.username}</span>, 
                  <span className={styles.duration}>Duration: {user.duration}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
    </>
  );
};

export default TimeTrackingData;
