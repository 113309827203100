import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axiosInstance from "../Utils/Utils";
import styles from "./assets/scss/time-tracking.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import User from "./time-tracking-manual";
import Timer from "./timer";
import Profile from "../Profile/Profile";

// redux
import { uiActions } from "../../store/ui/ui-slice";
import { useSelector, useDispatch } from "react-redux";

const MainUser = () => {
  const dispatch = useDispatch();
  const { userId, userName } = useSelector((state) => state.app.allData);

  const [value, setValue] = useState("1");

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isTiming, setIsTiming] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [queriedProjects, setQueriedProjects] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL || "";

  const [Task, setTask] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [timer, setTimer] = useState("00:00:00");

  const [disableStart, setDisableStart] = useState(false);
  const [disableEnd, setDisableEnd] = useState(true);
  const [timerRunning, setTimerRunning] = useState(false);
  const [showEndButton, setShowEndButton] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (startTime) {
      setIsTiming(true);
    }
  }, [startTime]);

  useEffect(() => {
    if (isTiming) {
      setDisableStart(true);
      setDisableEnd(false);
    }
  }, [isTiming]);

  useEffect(() => {
    if (isTiming) {
      localStorage.setItem("startTime", startTime);
    } else {
      localStorage.removeItem("startTime");
    }
  }, [isTiming, startTime]);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.post(
        `${apiURL}/api/check_time_tracking_status/`,
        {
          memberId: userId,
        }
      );
      const { isOk, message, status, start_time } = response.data;
      if (isOk) {
        setStartTime(start_time);
        if (status === "active") {
          setDisableEnd(false);
          setShowEndButton(true);
        } else if (status === "inactive") {
          setStartTime("");
          setDisableEnd(false);
        }
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "success",
          })
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleQuerySelectorClick = async () => {
    try {
      const response = await axiosInstance.get(
        apiURL + `/api/member/${userId}/projects/`
      );
      const { isOk, message, code } = response.data;
      if (isOk) {
        const { status, start_time } = response.data;
        if (response.data.projects) {
          setQueriedProjects(response.data.projects);
        }
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching projects:", error.message);
    }
    setShowTable(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (projectId, projectName) => {
    setTimeout(() => {
      setAnchorEl(null);
      setOpen(false);
      if (projectId && projectName) {
        setSelectedProject({ id: projectId, name: projectName });
      }
    }, 100);
  };
  const isValidDuration = (duration) => {
    const regex = /^([0-4][0-9]|5[0-9]):([0-5][0-9]):([0-5][0-9])$/;
    if (!regex.test(duration)) return false;
  
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    if (hours > 5) return false;
    
    return true;
  };
  const convertUTCToLocal = (utcTime) => {
    const localTime = new Date(`${utcTime}+00:00`);
    console.log(
      `Converting UTC time ${utcTime} to local time: ${localTime.toLocaleString()}`
    );
    return localTime.toLocaleString();
  };
  const handleStart = async () => {
    console.log("-----");
    setDisableStart(true);
    setIsTiming(true);
  
    dispatch(
      uiActions.updateNotification({
        level: "success",
      })
    );
  
    try {
      const response = await axiosInstance.post(
        apiURL + "/api/add-start-time/",
        {
          memberId: userId,
          projectId: selectedProject.id,
          task: Task,
        }
      );
      const { isOk, message, startTime } = response.data;
      if (isOk) {
        const localStartTime = convertUTCToLocal(startTime);
        setStartTime(localStartTime);
        setDisableEnd(false);
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error sending start time:", error.message);
    }
  };
  const handleEnd = async () => {
    console.log("-----");
    setDisableStart(false);
    const currentUTCTimeEnd = new Date().toISOString();
    const formattedEndDateTime = convertUTCToLocal(currentUTCTimeEnd);
  
    const actualDurationMilliseconds = new Date() - new Date(startTime);
    const actualDuration = formatElapsedTime(actualDurationMilliseconds);
  
    const durationInput = prompt(
      "Enter the duration (in format HH:mm:ss):",
      timer
    );
  
    if (durationInput !== null) {
      if (!isValidDuration(durationInput)) {
        alert("Please enter a valid duration between 00:00:00 and 05:00:00.");
        return;
      }
  
      const [inputHours, inputMinutes, inputSeconds] = durationInput
        .split(":")
        .map(Number);
      const inputDurationInMilliseconds =
        inputHours * 3600000 + inputMinutes * 60000 + inputSeconds * 1000;
  
      if (inputDurationInMilliseconds > actualDurationMilliseconds) {
        alert(
          "The entered duration cannot be more than the calculated duration. Please enter a valid duration."
        );
        return;
      }
  
      const data = {
        memberId: userId,
        duration: durationInput,
        end_time: formattedEndDateTime,
      };
  
      if (durationInput === timer) {
        data.auto = "automatic";
      }
  
      try {
        const response = await axiosInstance.post(
          apiURL + "/api/add-time-tracking/",
          data
        );
        const { isOk, message, code } = response.data;
        if (isOk) {
          console.log("Time tracking data sent successfully!");
          setStartTime("");
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "error",
            })
          );
        }
      } catch (error) {
        console.error("Error sending time tracking data:", error.message);
      }
  
      setTask("");
      setSelectedProject(null);
      setIsTiming(false);
      setDisableEnd(true);
    }
  };
  
      
  
  

  useEffect(() => {
    let interval;

 if (startTime && isTiming) {
  const storedStartTime = new Date(startTime);
  console.log("storedStartTime ", storedStartTime);

  interval = setInterval(() => {
    const now = new Date();
    console.log("now ", now);

    const elapsed = now - storedStartTime; 
    console.log("elapsed ", elapsed);

    const formattedElapsedTime = formatElapsedTime(elapsed);
    setTimer(formattedElapsedTime);
    console.log("formatted Elapsed Time:", formattedElapsedTime);
  }, 1000);
}


    return () => {
      clearInterval(interval);
    };
  }, [startTime, isTiming]);

  const formatElapsedTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  useEffect(() => {
    const fetchMemberProjects = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiURL}/api/member-projects/${userId}/`
        );
        const { isOk, message, data } = response.data;
        if (isOk && data) {
          setProjectList(data);
          console.log(data);
        } else {
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "error",
            })
          );
        }
      } catch (error) {

        console.error("Error fetching member projects:", error.message);
      }
    };

    fetchMemberProjects();
  }, [userId]);

  // const formatElapsedTime = (time) => {
  //   const hours = Math.floor(time / 3600000);
  //   const minutes = Math.floor((time % 3600000) / 60000);
  //   const seconds = Math.floor((time % 60000) / 1000);

  //   return `${hours.toString().padStart(2, "0")}:${minutes
  //     .toString()
  //     .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  // };

  useEffect(() => {
    console.log(startTime);
    console.log(Task);
    console.log(selectedProject);
    console.log(startTime !== undefined && Task === "" && !selectedProject);
  }, [startTime]);

  useEffect(() => {
    if (selectedProject) {
      localStorage.setItem("selectedProject", JSON.stringify(selectedProject));
    }
  }, [selectedProject]);
  return (
    <>
      <section className={styles.bg}>
        <h2 className={styles.title}>
          Welcome {userName ? userName : "Guest"}
        </h2>
        <Card className={styles.card}>
          <CardContent>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab className={styles.tabz} label="Tracker" value="1" />
                    <Tab
                      className={styles.tabz}
                      label="Manual Tracker"
                      value="2"
                    />
                      <Tab
                      className={styles.tabz}
                      label="Profile"
                      value="3"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                <div className={styles.container}>
                    <Box style={{ display: isTiming ? "none" : "block" }}>
                      <Button
                        className={styles.menu}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        disabled={isTiming}
                      >
                        {selectedProject
                          ? selectedProject.name
                          : "Select Project"}
                      </Button>
                      <div style={{ display: "flex" }}>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={() => handleClose(null, null)}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {projectList.map((project) => (
                            <MenuItem
                              style={{ width: "250px" }}
                              className={styles.me}
                              key={project.id}
                              onClick={() =>
                                handleClose(project.id, project.name)
                              }
                            >
                              {project.name}
                            </MenuItem>
                          ))}
                        </Menu>
                        <input
                          className={styles.input2}
                          type="text"
                          value={Task}
                          placeholder="Task"
                          onChange={(e) => setTask(e.target.value)}
                        />
                      </div>
                      <br />
                      <button
                        className={styles.btn1}
                        onClick={handleQuerySelectorClick}
                      >
                        Query Projects
                      </button>
                    </Box>
                    <Button
                      className={styles.zor}
                      variant="contained"
                      color="success"
                      onClick={handleEnd}
                      disabled={disableEnd}
                    >
                      End
                    </Button>
                    {isTiming && (
                      <div>
                        <Timer startTime={timer} />
                      </div>
                    )}
                    {!isTiming && (
                      <Button
                        className={styles.btn}
                        variant="contained"
                        color="success"
                        onClick={handleStart}
                        disabled={
                          disableStart || Task === "" || !selectedProject
                        }
                      >
                        Start
                      </Button>
                    )}
                    <div>
                      {showTable && (
                        <table>
                          <thead>
                            <tr>
                              <th>Project Name</th>
                              <th>Total Duration</th>
                            </tr>
                          </thead>
                          <tbody>
                            {queriedProjects.map((project, index) => (
                              <tr key={index}>
                                <td>{project.project_name}</td>
                                <td>
                                  {project.total_duration
                                    ? project.total_duration.slice(4)
                                    : ""}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <User />
                </TabPanel>
         

                <TabPanel value="3">
                 <Profile />
                </TabPanel>
              </TabContext>
            </Box>
          </CardContent>
        </Card>
      </section>
    </>
  );
};

export default MainUser;
