import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, TextField } from "@mui/material";
import axiosInstance from "../Utils/Utils";
import { uiActions } from "../../store/ui/ui-slice";
import styles from "./Admin.module.css";

const CreateProject = () => {
  const apiURL = process.env.REACT_APP_API_URL || ""; // Provide a default value

  const [projectName, setProjectName] = useState<string>(""); // Specify the type for projectName
  const app = useSelector((state: any) => state.app.allData); // Adjust the type for state

  const dispatch = useDispatch();

  const handleCreate = () => {
    const requestData = {
      projectName: projectName,
      entityId: app.entityId,
    };

    axiosInstance
      .post(apiURL + "/api/add_project/", requestData)
      .then((response) => {
        console.log("Success:", response.data);
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: response.data.isOk ? "success" : "error",
          })
        );
      });
  };

  return (
    <>
      <section className={styles.bg}>
        <TextField
          className={styles.txt}
          id="outlined-basic"
          label="Project Name"
          variant="outlined"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          autoComplete="off"
        />
        <Button
          className={styles.bt}
          variant="contained"
          color="secondary"
          onClick={handleCreate}
          disabled={!projectName}
        >
          Create
        </Button>
      </section>
    </>
  );
};

export default CreateProject;
